import generateCrudRoutes from 'services/generateCrudRoutes'

const buildDatasetPayload = (dataset) => {
  const datasetAttributes: any = _.pick(dataset, ['description', 'runDaily'])
  return datasetAttributes
}

const aiEvaluationDatasets = generateCrudRoutes(
  '/api/admin/ai/evaluation/datasets',
  'dataset',
  {},
  buildDatasetPayload
)

export default aiEvaluationDatasets
