import generateCrudRoutes from 'services/generateCrudRoutes'
import Axios from 'axios'
import searchURI from 'services/searchURI'
import _ from 'lodash'

type FetchAllOptions = {
  datasetId?: string
  perPage?: number
  page?: number
}

const buildQuestionPayload = (question) => {
  const questionAttributes: any = _.pick(question, ['content', 'expectedAnswer', 'sources'])

  if (question.user) {
    questionAttributes.userId = question.user.id
  }

  if (question.dataset) {
    questionAttributes.datasetId = question.dataset.id
  }

  return questionAttributes
}

const aiEvaluationQuestions = {
  ...generateCrudRoutes(
    '/api/admin/ai/evaluation/questions',
    'question',
    {},
    buildQuestionPayload
  ),
  fetchAll: (options: FetchAllOptions = {}) => {
    const { datasetId, ...restOptions } = options
    const url = datasetId
      ? `/api/admin/ai/evaluation/datasets/${datasetId}/questions`
      : '/api/admin/ai/evaluation/questions'
    return Axios.get(searchURI(url, restOptions))
  },
}

export default aiEvaluationQuestions
